import React, { useState, useEffect } from 'react'
import styled from 'styled-components';

import Section from './layout/Section'
import Hero from './layout/Hero'
import RichText from './layout/RichText'
import Link from './layout/Link'

const Blog = ({ story, settings }) => {
	const [displayDate, setDisplayDate] = useState('')

	useEffect(() => {
		setDisplayDate(new window.Date(story.published_at).toLocaleString('de-DE', {
			year: 'numeric', month: 'long', day: 'numeric'
		}))
	}, [story.published_at])

	return (
		<>
			<Hero title={story.name} image={story.content.image} />
			<Section content={story.content} background="pattern" isReadWidth>
				<Lead>{story.content.lead}</Lead>

				<RichText text={story.content.text} />

				<AuthorWrapper>
					<Date dateTime={story.published_at}>{displayDate}</Date>
					<Author>Martina Pfister</Author>
				</AuthorWrapper>

				<OverviewLink to="/blog">Alle Blogbeiträge</OverviewLink>
				<ClearFix />
			</Section>
		</>
	)
}

export default Blog

const Lead = styled.strong`
  display: block;
  font-size: 1.3rem;
  font-weight: lighter;
  margin: 0 0 1.5rem 0;
`;

const OverviewLink = styled(Link)`
  display: block;
  margin: 1.5rem 0 0 0;
  float: right;
`;

const ClearFix = styled.div`
  &::after {
    content: "";
    clear: both;
    display: table;
  }
`;

const AuthorWrapper = styled.div`
  margin: 2rem 0 0 0;
`;

const Author = styled.div`
  font-size: 2rem;
  font-family: ${({ theme }) => theme.font.heading};
`;

const Date = styled.time`
  display: block;
  font-weight: lighter;
  margin: 0 0 .5rem 0;
`;