import React from 'react'
import Blog from '../components/Blog.js'
import Layout from '../layout'

class StoryblokEntry extends React.Component {
	static getDerivedStateFromProps(props, state) {
		if (state.story.uuid === props.pageContext.story.uuid) {
			return null
		}

		return StoryblokEntry.prepareStory(props)
	}

	static prepareStory(props) {
		const story = Object.assign({}, props.pageContext.story)
		story.content = JSON.parse(story.content)
		const settings = props.pageContext.settings

		// generate seo tags from blog content
		settings.seo.title = `${story.name} | Martina Pfister Coaching`
		settings.seo.description = story.content.lead

		return { story, settings }
	}

	constructor(props) {
		super(props)

		this.state = StoryblokEntry.prepareStory(props)
	}

	render() {
		let story = this.state.story
		let settings = this.state.settings

		return (
			<Layout settings={settings}>
				<Blog story={story} settings={settings} />
			</Layout>
		)
	}
}

export default StoryblokEntry
